<template>
  <div class="CedantItem">
    <div class="row mt-4">
      <div class="col-12 col-md-6">
        <strong> Prénom </strong> : {{cedant.user.first_name}}
      </div>
      <div class="col-12 col-md-6">
        <strong> Nom </strong> : {{cedant.user.last_name}}
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <strong> Email </strong> : {{cedant.user.username}}
      </div>
      <div class="col-12 col-md-6">
        <strong> Téléphone </strong> : {{cedant.phone_number.match(/.{1,2}/g).join(' ')}}
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <strong> Adresse </strong> : {{cedant.address}}, {{cedant.zip_code}} {{cedant.city}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CedantItem',
  props: {
    cedant: Object,
  },
}
</script>
